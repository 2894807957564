.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: 'black'    }
  
  .navbar h1 {
    font-size: 24px;
    font-family: 'Press Start 2P', cursive;
  }

  .rainbow-text{
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .navbar .links a {
    margin: 0 10px;
    color: white;
    display: flex;
    text-decoration: none;
    font-family: 'Press Start 2P', cursive;

  }
  
  .navbar .links a:hover {
    text-decoration: underline;
  }
  
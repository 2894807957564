.home {
    padding: 20px;
  }
  
  .rainbow-text{
    background-image: linear-gradient(to left, red, orange, green, blue, yellow, purple, white);
    background-clip:unset;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .icon {
    width: 10px;
    height: 10px;
  }

  .gifsize{
    width: 500px;
    height: 500px;
  }